<template>
  <Header title="Account" subtitle="Modify your profile and settings"></Header>
  <form v-on:submit.prevent="onSubmit" @submit="submitForm">
    <div class="column left">
      <div class="control">
        <label>E-mail</label>
        <input v-model="email" type="email" placeholder="E-mail" />
      </div>
      <div class="control">
        <label>Name</label>
        <input v-model="name" type="text" placeholder="Name" />
      </div>
      <div class="control">
        <label>City</label>
        <input v-model="city" type="text" placeholder="City" />
      </div>
      <div class="control">
        <label>Date of birth</label>
        <input v-model="city" type="text" placeholder="yyyy-mm-dd" />
      </div>
      <div class="control">
        <button type="submit" class="button">
          <inline-svg :src="require('../assets/icons/save.svg')"></inline-svg>
          Save
        </button>
      </div>
    </div>
    <div class="column left">
      <div class="control">
        <label>E-mail</label>
        <input v-model="email" type="email" placeholder="E-mail" />
      </div>
      <div class="control">
        <label>Name</label>
        <input v-model="name" type="text" placeholder="Name" />
      </div>
    </div>
  </form>
</template>

<script>
import stringMixin from "@/mixins/string.mixin";

export default {
  name: "Account",
  mixins: [stringMixin],
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="sass" scoped>
form
  margin-top: 4rem
  text-align: left
  display: flex
  flex-direction: row
  justify-content: center
  gap: 20%

  @media (max-width: $media-mobile)
    flex-direction: column-reverse

  .column
    display: flex
    flex-direction: column
    width: 25%

  button[type="submit"]
    margin-top: 4rem
</style>
